import React from "react";
import { Header, Segment } from "semantic-ui-react";

import PAYMENT_AMOUNT from "./paymentAmount";

type State = any;

export default class PaymentOption extends React.Component<{}, State> {
  props: any;
  state = {
    hovered: false,
  };
  handleEnter = () => {
    this.setState({
      hovered: true,
    });
  };
  handleExit = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const { clicked, handleClick, product } = this.props;
    const { hovered } = this.state;

    const dollars = product?.price / 100;
    const borderStyle =
      clicked === product?.product_id
        ? "2px solid rgba(0,0,0,1)"
        : hovered
        ? "2px solid rgba(0,0,0,.1)"
        : "2px solid transparent";

    const cursorStyle = hovered ? "pointer" : "auto";
    return (
      <Segment
        style={{
          border: borderStyle,
          cursor: cursorStyle,
        }}
        basic
        textAlign="center"
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleExit}
        onClick={(p: any) => handleClick(product?.product_id)}
      >
        <Header>{dollars ? `$${dollars}` : null}</Header>
        <p>{product?.name}</p>
      </Segment>
    );
  }
}
