import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  List,
  Menu,
  Segment,
  SemanticICONS,
  Visibility,
} from "semantic-ui-react";

import { Switch, Route, Link, useLocation } from "react-router-dom";
import Intro from "../Intro";
import Tos from "../Tos/Tos";
import Privacy from "../Privacy/Privacy";

import { BACKEND } from "../../url";
const AUTH_URL = `${BACKEND}/v1/auth/youtube`;

// import twitch_icon from "./twitch.png"
// import youtube_icon from "./youtube.png"

export default function LoggedOut(props: any) {
  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  return (
    <div>
      <Visibility
        onBottomPassed={() => setVisible(true)}
        onBottomVisible={() => setVisible(false)}
        once={false}
      >
        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 700, padding: "1em 0em" }}
          vertical
        >
          <Container>
            <Menu inverted pointing secondary size="large">
              <Link to="/">
                <Menu.Item active={location.pathname === "/"}>Home</Menu.Item>{" "}
              </Link>
              <Menu.Item position="right">
                <Button
                  as="a"
                  href={AUTH_URL}
                  inverted
                  style={{ marginLeft: "0.5em" }}
                >
                  Connect
                </Button>
              </Menu.Item>
            </Menu>
          </Container>

          <Container text>
            <Header
              as="h1"
              content="clipthat"
              inverted
              style={{
                fontSize: "4em",
                fontWeight: "normal",
                marginBottom: 0,
                marginTop: "3em",
              }}
            />
            <Header
              as="h2"
              content="Automatic YouTube videos from your Twitch content."
              inverted
              style={{ fontSize: "1.7em", fontWeight: "normal" }}
            />
            <Segment inverted>
              To get started, we'll need permission to upload on your YouTube
              channel.
            </Segment>
            <Button as="a" href={AUTH_URL} primary size="huge">
              Get started
              <Icon name={"right arrow" as SemanticICONS} />
            </Button>
          </Container>
        </Segment>
      </Visibility>

      <Switch>
        <Route exact path="/">
          <Intro />
        </Route>
        <Route path="/tos">
          <Container style={{ minHeight: 800 }}>
            <Tos />
          </Container>
        </Route>
        <Route path="/privacy">
          <Container style={{ minHeight: 800 }}>
            <Privacy />
          </Container>
        </Route>
      </Switch>

      <Segment inverted vertical style={{ padding: "4em 0em" }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Contact" />
                <List link inverted>
                  <List.Item as="a">support@clipthat.org</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link to="/tos">Terms of Service</Link>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link to="/privacy">Privacy Policy</Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
}
