import React from "react";
import { List } from "semantic-ui-react";

export default class VideoList extends React.Component {
  props: any;
  render() {
    const { videos, selectedVideos, style } = this.props;
    return (
      <div style={style ? style : {}}>
        <List divided relaxed style={{ overflowY: "scroll", height: "100%" }}>
          {videos.map((ele: any, index: any) => {
            const selected_id = selectedVideos.findIndex(
              (sv: any) => sv.id === ele.id
            );

            // let border = is_selected ? { borderColor: ele.color, border: "0px 0px 10px 0px" } : {}
            // let background = is_selected ? { backgroundColor: ele.color } : {};

            return (
              <List.Item
                key={index}
                onClick={(e) => this.props.handleClick(e, index)}
              >
                <List.Icon verticalAlign="middle" size="large">
                  {selected_id === -1 ? "" : selected_id + 1}
                </List.Icon>
                <List.Content>
                  <List.Header>{ele.title}</List.Header>
                  <List.Description>{ele.views} views</List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}
