import React from "react";
import VideoList from "./VideoList";
import VideoEditor from "./VideoEditor";
import VideoPlayer from "./VideoPlayer";
import { Media } from "../Media";

export default class AdvancedVideo extends React.Component {
  props: any;
  render() {
    const { videos, orderedVideos, selectedVideos, currentSlug } = this.props;

    if (videos.length === 0) {
      return null;
    }
    return (
      <>
        <Media greaterThan="sm">
          <div>
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                // flexWrap: "wrap",
                alignItems: "stretch",
              }}
            >
              <VideoList
                style={{
                  display: "inline-block",
                  height: "400px",
                  flexGrow: "1",
                  border: "2px solid rgba(0,0,0,.1)",
                  cursor: "pointer",
                  padding: "1em",
                }}
                videos={videos}
                selectedVideos={selectedVideos}
                handleClick={this.props.handleSelectedVideo}
              />
              <VideoPlayer slug={currentSlug} />
            </div>
            <VideoEditor
              orderedVideos={orderedVideos}
              selectedVideos={selectedVideos}
              onDragEnd={this.props.onDragEnd}
            />
          </div>
        </Media>
        <Media at="sm">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                // flexWrap: "wrap",
                alignItems: "stretch",
              }}
            >
              <VideoPlayer slug={currentSlug} />
              <VideoList
                style={{
                  display: "inline-block",
                  height: "400px",
                  flexGrow: "1",
                  border: "2px solid rgba(0,0,0,.1)",
                  cursor: "pointer",
                  padding: "1em",
                }}
                videos={videos}
                selectedVideos={selectedVideos}
                handleClick={this.props.handleSelectedVideo}
              />
            </div>
            <VideoEditor
              orderedVideos={orderedVideos}
              selectedVideos={selectedVideos}
              onDragEnd={this.props.onDragEnd}
            />
          </div>
        </Media>
      </>
    );
  }
}
