export const BACKEND =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.clipthat.org"
    : "http://localhost:5000";
export const FRONTEND =
  process.env.REACT_APP_ENV === "production"
    ? "https://clipthat.org"
    : "http://localhost:3000";

export function removeHTTP(url: any) {
  url = url.replace("https://", "");
  url = url.replace("http://", "");

  return url;
}

export function removePort(url: any) {
  const regex = /:[0-9]*/;
  const result = url.replace(regex, "");
  return result;
}

export function trimURL(url: any) {
  url = removeHTTP(url);
  url = removePort(url);

  return url;
}
