import { Button, Icon, Loader } from "semantic-ui-react";
import react from "react";

export const UPLOAD_STATE = {
  FAILED: "FAILED",
  PASSED: "PASSED",
  PRIOR: "PRIOR",
  NO_PRIOR: "NO_PRIOR",
  IN_PROGRESS: "IN_PROGRESS",
};

interface FileUploaderProps {
  type: string;
  files: File[];
  style?: react.CSSProperties;
  imageX: number;
  imageY: number;
  uploadState: string;
  handleSelect(e: react.ChangeEvent<HTMLInputElement>): void;
  handleUpload(): void;
  handleRemove(e: any, index: number): void;
}
export default function FileUploader(props: FileUploaderProps) {
  let indicator = null;
  if (props.uploadState === UPLOAD_STATE.NO_PRIOR) {
    indicator = null;
  } else if (props.uploadState === UPLOAD_STATE.IN_PROGRESS) {
    indicator = <Loader size="mini" inline active />;
  } else if (props.uploadState === UPLOAD_STATE.FAILED) {
    indicator = <Icon color="red" name="warning circle" />;
  } else if (props.uploadState === UPLOAD_STATE.PASSED) {
    indicator = <Icon color="green" name="check" />;
  } else if (props.uploadState === UPLOAD_STATE.PRIOR) {
    indicator = <Icon name="check" />;
  }
  return (
    <>
      <div style={props.style}>
        {props.files.map((f, index) => {
          const id = `${f.name}-${index}`;
          return (
            <div
              key={id}
              onClick={(e: any) => {
                props.handleRemove(e, index);
              }}
            >
              <img
                style={{
                  pointerEvents: "all",
                  background:
                    "linear-gradient(98deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%)",
                }}
                alt="test"
                width={props.imageX}
                height={props.imageY}
                src={URL.createObjectURL(f)}
              />
            </div>
          );
        })}
      </div>
      <div>
        <Button
          as="label"
          htmlFor={`${props.type}-button`}
          icon="file"
          type="button"
          content={"Select File"}
        />
        <input
          type="file"
          multiple
          id={`${props.type}-button`}
          hidden
          onChange={(e: react.ChangeEvent<HTMLInputElement>) =>
            props.handleSelect(e)
          }
        />
        <Button
          icon="upload"
          type="button"
          content={"Upload"}
          onClick={(e) => props.handleUpload()}
        />
        {indicator}
      </div>
    </>
  );
}
