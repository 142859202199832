import React from "react";
import {
  FUNDING,
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";

import {
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js/types/components/buttons";
/**
 * paypal related configuration
 */
const clientId =
  process.env.REACT_APP_ENV === "production"
    ? //production
      "AXpdbM52bI7wDJhYl16floAeqff_iXjxNAD91U6mAJ4TikHBNa6ZUdTyjvDItcCHI50fFhUs7Ca94nOP"
    : //sandbox
      "AR_6onSRXSk2Q7ZgMrngNgJwENjYRXqm9TpfF877g-N9hgkKJwgalkQ06uVqb_aZDqFOQmkrSKUCVZcu";

const initialOptions = {
  "client-id": clientId,
  currency: "USD",
  vault: true,
  intent: "subscription",
};

export default class PaypalSubscription extends React.Component {
  props: any;
  createSubscription = (data: any, actions: any) => {
    const { planId } = this.props;
    return actions.subscription.create({
      //todo pass subscription type dynamically
      plan_id: planId,
    });
  };

  onError = (err: any) => {
    console.error(err);
  };

  onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    // onApprove = async (data: any, actions: any) => {
    console.log(data);
    this.props.handlePayment(data);
  };

  render() {
    return (
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createSubscription={this.createSubscription}
          fundingSource={FUNDING.PAYPAL}
          onApprove={this.onApprove}
          style={{
            color: "black",
            shape: "rect",
            label: "checkout",
          }}
        />
      </PayPalScriptProvider>
    );
  }
}
