import React, { Component } from "react";
import {
  Form,
  Container,
  Grid,
  Radio,
  Header,
  Segment,
  Input,
} from "semantic-ui-react";
import debounce from "lodash/debounce";
import { UPLOAD_STATE, UploadButton } from "./UploadButton";
import Paypal from "./Paypal";
import PaypalSubscription from "./PaypalSubscription";
import PaymentOption from "./PaymentOption";
import AdvancedVideo from "./AdvancedVideo";
import PAYMENT_AMOUNT from "./paymentAmount";
import { BACKEND } from "../url";
import { Redirect } from "react-router-dom";
import ErrorBoundry from "./ErrorBoundry";
import { OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { SubscriptionBody } from "../../types/request-body";
import ThumbnailUploader from "./ThumbnailUploader";
import IntroOutro from "./IntroOutro";

const BIG_NAMES = ["ninja", "shroud", "pokimane", "xqcow"];
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

async function activeSubscriptions(youtubeId: string) {
  const query = new URLSearchParams({
    youtubeId,
    active: "true",
  });
  const result = await fetch(`${BACKEND}/v1/subscription?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

// Key: `${youtubeId}_${imageType}_${imagePosition}`,
async function uploadThumbnailQuery(
  youtubeId: string,
  imageType: string,
  imagePosition: number
) {
  const query = new URLSearchParams({
    youtubeId,
    imageType,
    imagePosition: imagePosition.toString(),
  });
  const result = await fetch(`${BACKEND}/v1/uploadThumbnailUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

async function uploadQuery(youtubeId: any, videoType: any) {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/uploadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
async function downloadThumbnailQuery(youtubeId: string) {
  const query = new URLSearchParams({
    youtubeId,
  });
  const result = await fetch(`${BACKEND}/v1/downloadThumbnailUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
async function downloadQuery(youtubeId: any, videoType: any) {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/downloadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
async function getPlans() {
  const result = await fetch(`${BACKEND}/v1/plan`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

async function getProducts() {
  const result = await fetch(`${BACKEND}/v1/product`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
type State = any;
export default class Subscribe extends Component<{}, State> {
  props: any;
  async componentDidMount() {
    const settingsResponse = await fetch(`${BACKEND}/v1/settings`, {
      method: "GET",
      credentials: "include",
    }).then((res) => res.json());
    const introUpload = await uploadQuery(this.props.youtubeId, "intro");
    const outroUpload = await uploadQuery(this.props.youtubeId, "outro");
    if (!settingsResponse.twitchName) {
      settingsResponse.twitchName =
        BIG_NAMES[Math.floor(Math.random() * BIG_NAMES.length)];
    }

    const thumbnailDownloadUrl = await downloadThumbnailQuery(
      this.props.youtubeId
    );

    const thumbnailButtonStates: any = {};
    thumbnailButtonStates.background = thumbnailDownloadUrl.backgroundUrls.map(
      (url: string) => {
        return !url ? UPLOAD_STATE.NO_PRIOR : UPLOAD_STATE.PRIOR;
      }
    );
    thumbnailButtonStates.foreground = thumbnailDownloadUrl.foregroundUrls.map(
      (url: string) => {
        return !url ? UPLOAD_STATE.NO_PRIOR : UPLOAD_STATE.PRIOR;
      }
    );

    console.log(thumbnailButtonStates);
    // updating videos refreshes the twitch iframe player. doing it on initialization
    // means we dont start with an empty one on initial load
    const useIntroOutro = settingsResponse.useIntroOutro
      ? settingsResponse.useIntroOutro
      : false;

    const products = await getProducts();
    const plans = await getPlans();

    const activeSubs = await activeSubscriptions(this.props.youtubeId);

    this.setState({
      contactEmail: settingsResponse.contactEmail,
      twitchName: settingsResponse.twitchName,
      useIntroOutro: useIntroOutro,
      introUploadUrl: introUpload.uploadUrl,
      outroUploadUrl: outroUpload.uploadUrl,
      products,
      plans,
      thumbnailDownloadUrl,
      thumbnailButtonStates,
      introButtonState: "NO_PRIOR",
      outroButtonState: "NO_PRIOR",
      backgroundState: "NO_PRIOR",
      foregroundState: "NO_PRIOR",
      activeSubs,
    });
  }
  state = {
    backgroundState: "NO_PRIOR",
    foregroundState: "NO_PRIOR",
    activeSubs: Array(),
    numClips: 10,
    thumbnailButtonStates: {
      background: [],
      foreground: [],
    },
    useThumbnail: false,
    censor: false,
    thumbnailDownloadUrl: Object(),
    products: Array(),
    plans: Array(),
    planIdClicked: 2,
    contactEmail: "",
    initialTwitchName: "",
    useIntroOutro: false,
    twitchName: "",
    introButtonState: UPLOAD_STATE.NO_PRIOR,
    outroButtonState: UPLOAD_STATE.NO_PRIOR,
    /**
     * videos sent to us from server
     */
    videos: Array(),
    /**
     * videos selected in the video list. remains sorted by viewcount over selection order
     */
    selectedVideos: Array(),
    /**
     * the modified order of selected videos by the user
     */
    orderedVideos: Array(),
    currentSlug: "",
    paymentValidated: false,
    paymentFailed: false,
  };

  setBackgroundState = (bgState: string) => {
    this.setState({
      backgroundState: bgState,
    });
  };

  setForegroundState = (fgState: string) => {
    this.setState({
      foregroundState: fgState,
    });
  };

  constructor(props: {}) {
    super(props);
  }
  handleTwitchName = (e: any) => {
    const newName = e.target.value;
    const { planIdClicked } = this.state;
    this.setState({
      twitchName: newName,
    });
  };
  setProductIdClicked = (type: any) => {
    this.setState({
      planIdClicked: type,
    });
  };
  saveSettings = () => {
    const { contactEmail, twitchName, useIntroOutro } = this.state;
    const fetchData = async () => {
      const url = `${BACKEND}/v1/settings`;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactEmail,
          twitchName,
          useIntroOutro,
        }),
      });
      return response;
    };
    fetchData();
  };
  handlePayment = async (data: OnApproveData) => {
    this.saveSettings();
    const {
      twitchName,
      useIntroOutro,
      useThumbnail,
      censor,
      planIdClicked,
      numClips,
      contactEmail,
      // censored
      // youtubeTitle,
      // youtubeDescription,
      // youtubePrivacy,
    } = this.state;
    const planMap = ["day", "week", "month"];

    const { youtubeId } = this.props;

    const payload: SubscriptionBody = {
      youtubeId,
      subscriptionId: data.subscriptionID as string,
      twitchName,
      useIntroOutro,
      useThumbnail,
      censor,
      type: planMap[planIdClicked],
      numClips,
      email: contactEmail,
    };

    try {
      const resp = await fetch(`${BACKEND}/v1/subscription`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (resp.ok) {
        console.log("were ok");
        // There's a timing issue with the official Paypal component getting demounted during a route change.
        // we add some delay to avoid this
        setTimeout(
          () =>
            this.setState({
              paymentValidated: true,
              paymentFailed: false,
            }),
          5000
        );
      } else {
        console.error(resp.status);
        throw Error("fetch didnt return ok");
      }
    } catch (err) {
      this.setState({
        paymentValidated: false,
        paymentFailed: true,
      });
      console.error(err);
    }
  };
  render() {
    const {
      planIdClicked,
      products,
      plans,
      paymentValidated,
      introButtonState,
      outroButtonState,
      useIntroOutro,
    } = this.state;
    const amount = products[planIdClicked]?.price / 100;
    // redirect to videos page
    const OnPaymentValidated = paymentValidated ? (
      <Redirect to="/videos" />
    ) : null;
    let PaypalButton = (
      <PaypalSubscription
        planId={plans[planIdClicked]?.plan_id}
        handlePayment={this.handlePayment}
      />
    );
    if (paymentValidated) {
      PaypalButton = <></>;
    }
    const noShowStates = [UPLOAD_STATE.FAILED, UPLOAD_STATE.NO_PRIOR];
    if (
      useIntroOutro &&
      (noShowStates.some((state) => state === introButtonState) ||
        noShowStates.some((state) => state === outroButtonState))
    ) {
      PaypalButton = (
        <Header>Upload valid intro and outro videos to checkout</Header>
      );
    }
    if (
      introButtonState === UPLOAD_STATE.IN_PROGRESS ||
      outroButtonState === UPLOAD_STATE.IN_PROGRESS
    ) {
      PaypalButton = <Header>Uploading...</Header>;
    }
    const optionalButtonGroup = !this.state.useIntroOutro ? null : (
      <>
        <Form.Group>
          <Form.Field>
            <IntroOutro
              buttonState={introButtonState}
              handleButtonState={(state: string) =>
                this.setState({ introButtonState: state })
              }
              content={"Intro video"}
              videoType={"intro"}
              youtubeId={this.props.youtubeId}
            />
          </Form.Field>
          <Form.Field>
            <IntroOutro
              buttonState={outroButtonState}
              handleButtonState={(state: string) =>
                this.setState({ outroButtonState: state })
              }
              content={"Outro video"}
              videoType={"outro"}
              youtubeId={this.props.youtubeId}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Header as="h6">
              720p video with the same framerate as your stream will work best.
            </Header>
          </Form.Field>
        </Form.Group>
      </>
    );
    const optionalThumbnailGroup = !this.state.useThumbnail ? null : (
      <>
        <Form.Group>
          <Form.Field>
            <Segment>
              <Header as="h5">Background Images</Header>
              <Header as="h6">
                Background images should be ~1280x720 resolution.
              </Header>
              <ThumbnailUploader
                thumbnailType="bg"
                imageX={160}
                imageY={90}
                uploadState={this.state.backgroundState}
                setLoadState={this.setBackgroundState}
                youtubeId={this.props.youtubeId}
              />
            </Segment>
          </Form.Field>
          <Form.Field></Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Segment>
              <Header as="h5">Foreground Images</Header>
              <Header as="h6">
                Foreground images should be ~426x720 resolution.
              </Header>
              <ThumbnailUploader
                thumbnailType="fg"
                imageX={53}
                imageY={90}
                uploadState={this.state.foregroundState}
                setLoadState={this.setForegroundState}
                youtubeId={this.props.youtubeId}
              />
            </Segment>
          </Form.Field>
        </Form.Group>
      </>
    );
    return (
      <div>
        {OnPaymentValidated}

        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 200, padding: "1em 0em" }}
          vertical
        >
          <Container>
            <Header
              as="h1"
              content="Subscriptions"
              inverted
              style={{
                fontSize: "4em",
                fontWeight: "normal",
                marginBottom: 0,
                marginTop: "1.5em",
              }}
            />
          </Container>
        </Segment>

        <Container style={{ marginTop: "4em" }}>
          {this.state.activeSubs.map((sub) => {
            return <div>{`Active subscription for ${sub.type}`}</div>;
          })}
        </Container>
        <Container style={{ marginTop: "4em" }}>
          <Header>Select the video type you want</Header>
          <Radio
            label="Daily videos"
            name="planGroup"
            value={0}
            checked={this.state.planIdClicked === 0}
            onChange={this.handleSubscribeOption}
          />
          <Radio
            label="Weekly videos"
            name="planGroup"
            value={1}
            checked={this.state.planIdClicked === 1}
            onChange={this.handleSubscribeOption}
          />

          <Radio
            label="Monthly Videos"
            name="planGroup"
            value={2}
            checked={this.state.planIdClicked === 2}
            onChange={this.handleSubscribeOption}
          />
        </Container>

        <Form size="big">
          <Container style={{ marginTop: "4em" }}>
            <Form.Group>
              <Form.Field>
                <label>Enter your Twitch username</label>
                <Input
                  placeholder="Twitch Username"
                  defaultValue={this.state.twitchName}
                  onChange={this.handleTwitchName}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Enter your contact email</label>
                <Input
                  name="email"
                  placeholder={"email"}
                  defaultValue={this.state.contactEmail}
                  onChange={(e) =>
                    this.setState({ contactEmail: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Field>
                <Form.Checkbox
                  label="Include an intro and outro video"
                  checked={this.state.useIntroOutro}
                  onClick={(e) =>
                    this.setState({
                      useIntroOutro: !this.state.useIntroOutro,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            {optionalButtonGroup}
          </Container>

          <Container>
            <Form.Group>
              <Form.Field>
                <Form.Checkbox
                  label="include thumbnails"
                  checked={this.state.useThumbnail}
                  onClick={(e) =>
                    this.setState({
                      useThumbnail: !this.state.useThumbnail,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            {optionalThumbnailGroup}
          </Container>

          <Container>
            <Form.Group>
              <Form.Field>
                <Form.Checkbox
                  label="Try to censor inappropriate content"
                  checked={this.state.censor}
                  onClick={(e) =>
                    this.setState({
                      censor: !this.state.censor,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Container>
        </Form>

        <Container text style={{ margin: "4em 0em" }}>
          <Form.Group>
            <Form.Field>
              <label>Number of clips per video</label>
              <Input
                placeholder="number of clips"
                defaultValue={this.state.numClips}
                onChange={(e) => this.setState({ numClips: e.target.value })}
              />
            </Form.Field>
          </Form.Group>
        </Container>
        <Container text style={{ margin: "4em 0em" }}>
          <Header>
            After payment, a private Youtube video will be uploaded to your
            account.
          </Header>
        </Container>
        <Container text>
          <ErrorBoundry>{PaypalButton}</ErrorBoundry>
        </Container>
      </div>
    );
  }
  handleSubscribeOption = (e: any, { value }: any) =>
    this.setState({ planIdClicked: value });
}
