import React, { Component } from "react";
import {
  Form,
  Container,
  Grid,
  Header,
  Segment,
  Input,
  Radio,
  TextArea,
} from "semantic-ui-react";
import debounce from "lodash/debounce";
import { UPLOAD_STATE, UploadButton } from "./UploadButton";
import Paypal from "./Paypal";
import PaymentOption from "./PaymentOption";
import AdvancedVideo from "./AdvancedVideo";
import PAYMENT_AMOUNT from "./paymentAmount";
import { BACKEND } from "../url";
import { Redirect } from "react-router-dom";
import ErrorBoundry from "./ErrorBoundry";
import { PaymentBody } from "../../types/request-body";
const BIG_NAMES = ["ninja", "shroud", "pokimane", "xqcow"];
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
async function uploadQuery(youtubeId: any, videoType: any) {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/uploadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
async function downloadQuery(youtubeId: any, videoType: any) {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/downloadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

async function getProducts() {
  const result = await fetch(`${BACKEND}/v1/product`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}
type State = any;
export default class CreateVideo extends Component<{}, State> {
  props: any;
  slowUpdateVideos: any;
  async componentDidMount() {
    //init name, email, upload, download
    const settingsResponse = await fetch(`${BACKEND}/v1/settings`, {
      method: "GET",
      credentials: "include",
    }).then((res) => res.json());
    const introDownload = await downloadQuery(this.props.youtubeId, "intro");
    const outroDownload = await downloadQuery(this.props.youtubeId, "outro");
    const introUpload = await uploadQuery(this.props.youtubeId, "intro");
    const outroUpload = await uploadQuery(this.props.youtubeId, "outro");
    const introButtonState =
      introDownload.downloadUrl === ""
        ? UPLOAD_STATE.NO_PRIOR
        : UPLOAD_STATE.PRIOR;
    const outroButtonState =
      outroDownload.downloadUrl === ""
        ? UPLOAD_STATE.NO_PRIOR
        : UPLOAD_STATE.PRIOR;
    if (!settingsResponse.twitchName) {
      settingsResponse.twitchName =
        BIG_NAMES[Math.floor(Math.random() * BIG_NAMES.length)];
    }
    // updating videos refreshes the twitch iframe player. doing it on initialization
    // means we dont start with an empty one on initial load
    this.slowUpdateVideos(settingsResponse.twitchName, "month");
    // cascading errors from from a value being passed into json as undefined
    const useIntroOutro = settingsResponse.useIntroOutro
      ? settingsResponse.useIntroOutro
      : false;

    const products = await getProducts();

    this.setState({
      contactEmail: settingsResponse.contactEmail,
      twitchName: settingsResponse.twitchName,
      useIntroOutro: useIntroOutro,
      introDownloadUrl: introDownload.downloadUrl,
      outroDownloadUrl: outroDownload.downloadUrl,
      introUploadUrl: introUpload.uploadUrl,
      outroUploadUrl: outroUpload.uploadUrl,
      introButtonState,
      outroButtonState,
      products,
    });
  }
  state = {
    youtubeTitle: "Your clipthat.org video",
    youtubeDescription: "",
    /**
     * public, private, or unlisted
     */
    youtubePrivacy: "private",
    products: Array(),
    productIdClicked: 2,
    contactEmail: "",
    initialTwitchName: "",
    useIntroOutro: false,
    introDownloadUrl: "",
    outroDownloadUrl: "",
    twitchName: "",
    introButtonState: UPLOAD_STATE.NO_PRIOR,
    outroButtonState: UPLOAD_STATE.NO_PRIOR,
    /**
     * videos sent to us from server
     */
    videos: Array(),
    /**
     * videos selected in the video list. remains sorted by viewcount over selection order
     */
    selectedVideos: Array(),
    /**
     * the modified order of selected videos by the user
     */
    orderedVideos: Array(),
    currentSlug: "",
    paymentValidated: false,
    paymentFailed: false,
  };
  // TODO do this less stupidly
  handleIntro = (event: any) => {
    this.handleUpload(
      event,
      "introButtonState",
      (this.state as any).introUploadUrl
    );
  };
  handleOutro = (event: any) => {
    this.handleUpload(
      event,
      "outroButtonState",
      (this.state as any).outroUploadUrl
    );
  };
  handleUpload = async (event: any, buttonState: any, targetUrl: any) => {
    try {
      event.preventDefault();
      console.log(event.target);
      const file = event.target.files[0];
      if (!file.type.includes("video")) {
        console.warn("trying to upload non video file");
        return;
      }
      if (file.size > 50000000) {
        console.warn("trying to upload big boi file");
        return;
      }
      this.setState({
        [buttonState]: UPLOAD_STATE.IN_PROGRESS,
      });
      const result = await fetch(targetUrl, {
        method: "PUT",
        body: file,
      });
      if (result.ok) {
        this.setState({
          [buttonState]: UPLOAD_STATE.PASSED,
        });
        console.log("uploaded");
        // state indicating success
      } else {
        console.error("not ok ");
        this.setState({
          [buttonState]: UPLOAD_STATE.FAILED,
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        [buttonState]: UPLOAD_STATE.FAILED,
      });
    }
  };
  constructor(props: {}) {
    super(props);
    // the debounced version of updateVideos is used so that textinput for twitch username
    // doesnt flood server with api requests
    this.slowUpdateVideos = debounce(this.updateVideos, 1000);
  }
  onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const orderedVideos = reorder(
      this.state.orderedVideos,
      result.source.index,
      result.destination.index
    );
    console.log(orderedVideos);
    this.setState({
      orderedVideos,
    });
  };
  updateVideos = async (twitchName: any, productId: number) => {
    console.log("in updateVideos: ", twitchName, productId);
    let period = "month";
    switch (productId) {
      case 0:
        period = "day";
        break;
      case 1:
        period = "week";
        break;
      case 2:
        period = "month";
        break;
      default:
        period = "month";
        break;
    }
    const res = await fetch(
      `${BACKEND}/v1/clips?username=${twitchName}&period=${period}`,
      {
        method: "GET",
        credentials: "include",
      }
    ).then((data) => {
      if (!data.ok) {
        return [];
      }
      return data.json();
    });
    const videos = res.map((v: any, index: any) => {
      v.selected = false;
      v.position = null;
      v.id = `item-${index}`;
      return v;
    });
    const currentSlug = videos.length > 0 ? videos[0].slug : "";
    this.setState({
      videos,
      selectedVideos: videos.slice(0, 10),
      orderedVideos: videos.slice(0, 10),
      currentSlug,
    });
  };
  handleSelectedVideo = (e: any, index: any) => {
    const { videos } = this.state;
    let { selectedVideos, orderedVideos } = this.state;
    console.log(e, index);
    // if deselecting video, reorder the position indices of all selected items
    // we have redundant list selectedVideos (maintaining a numbered list of the selected items in order)
    // as well as ordered list, which is a copy of the elements from selectedVideos
    // that user can modify ordering
    if (
      selectedVideos.some((v) => (v as any).id === (videos[index] as any).id)
    ) {
      selectedVideos = selectedVideos.filter((v) => v.id !== videos[index].id);
      orderedVideos = orderedVideos.filter((v) => v.id !== videos[index].id);
      // if selecting video, just position index is just the incremented from greatest
    } else if (selectedVideos.length < 10) {
      selectedVideos.push(videos[index]);
      orderedVideos.push(videos[index]);
    }
    // maintain increasing order even if you click items in random positions in the list
    selectedVideos = selectedVideos.sort((a, b) => {
      return b.views - a.views;
    });
    // update the videoplayer with the newly clicked item regardless of select/deselect
    const currentSlug = (videos[index] as any).slug;
    selectedVideos = selectedVideos.slice(0, 10);
    orderedVideos = orderedVideos.slice(0, 10);
    this.setState({
      selectedVideos,
      orderedVideos,
      currentSlug,
    });
  };
  handleTwitchName = (e: any) => {
    const newName = e.target.value;
    const { productIdClicked } = this.state;
    this.setState({
      twitchName: newName,
    });
    this.slowUpdateVideos(newName, productIdClicked);
  };
  setProductIdClicked = (type: any) => {
    this.setState({
      productIdClicked: type,
    });
    // when changing the payment type we dont need debouncing
    this.updateVideos(this.state.twitchName, type);
  };
  saveSettings = () => {
    const { contactEmail, twitchName, useIntroOutro } = this.state;
    const fetchData = async () => {
      const url = `${BACKEND}/v1/settings`;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactEmail,
          twitchName,
          useIntroOutro,
        }),
      });
      return response;
    };
    fetchData();
  };
  handlePayment = async (paymentData: any) => {
    console.log(paymentData);
    this.saveSettings();
    const {
      twitchName,
      contactEmail,
      useIntroOutro,
      youtubeTitle,
      youtubeDescription,
      youtubePrivacy,
    } = this.state;
    let { orderedVideos } = this.state;
    const { youtubeId } = this.props;
    const paymentId = paymentData.id;
    // const paymentId = paymentData.payer.payer_id;
    const createTime = paymentData.create_time;
    //safer to get payment amount straight from paypal payload
    const paymentAmount = paymentData.purchase_units[0].amount.value;
    const paymentCurrency = paymentData.purchase_units[0].amount.currency_code;
    const payer = paymentData.payer;
    const payload: PaymentBody = {
      paymentId,
      youtubeId,
      twitchName,
      contactEmail,
      orderedVideos,
      createTime,
      paymentAmount,
      paymentCurrency,
      payer,
      useIntroOutro,
      youtubeTitle,
      youtubeDescription,
      youtubePrivacy,
    };
    try {
      const resp = await fetch(`${BACKEND}/v1/payment`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (resp.ok) {
        console.log("were ok");
        // There's a timing issue with the official Paypal component getting demounted during a route change.
        // we add some delay to avoid this
        setTimeout(
          () =>
            this.setState({
              paymentValidated: true,
              paymentFailed: false,
            }),
          5000
        );
      } else {
        throw Error("fetch didnt return ok");
      }
    } catch (err) {
      this.setState({
        paymentValidated: false,
        paymentFailed: true,
      });
      console.error(err);
    }
  };
  render() {
    const {
      productIdClicked,
      products,
      videos,
      selectedVideos,
      orderedVideos,
      currentSlug,
      paymentValidated,
      introButtonState,
      outroButtonState,
      useIntroOutro,
    } = this.state;
    const amount = products[productIdClicked]?.price / 100;
    // redirect to videos page
    const OnPaymentValidated = paymentValidated ? (
      <Redirect to="/videos" />
    ) : null;
    let PaypalButton = (
      <Paypal
        amount={amount}
        // amount={amount.toFixed(2)}
        handlePayment={this.handlePayment}
      />
    );
    if (paymentValidated) {
      PaypalButton = <></>;
    }
    const noShowStates = [UPLOAD_STATE.FAILED, UPLOAD_STATE.NO_PRIOR];
    if (
      useIntroOutro &&
      (noShowStates.some((state) => state === introButtonState) ||
        noShowStates.some((state) => state === outroButtonState))
    ) {
      PaypalButton = (
        <Header>Upload valid intro and outro videos to checkout</Header>
      );
    }
    if (
      introButtonState === UPLOAD_STATE.IN_PROGRESS ||
      outroButtonState === UPLOAD_STATE.IN_PROGRESS
    ) {
      PaypalButton = <Header>Uploading...</Header>;
    }
    const optionalButtonGroup = !(this.state as any).useIntroOutro ? null : (
      <>
        <Form.Group>
          <Form.Field>
            <UploadButton
              buttonState={(this.state as any).introButtonState}
              handleUpload={this.handleIntro}
              targetUrl={(this.state as any).introUploadUrl}
              content={"Intro video"}
            />
          </Form.Field>
          <Form.Field>
            <UploadButton
              buttonState={(this.state as any).outroButtonState}
              handleUpload={this.handleOutro}
              targetUrl={(this.state as any).outroUploadUrl}
              content={"Outro video"}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Header as="h6">
              720p video with the same framerate as your stream will work best.
            </Header>
          </Form.Field>
        </Form.Group>
      </>
    );
    return (
      <div>
        {OnPaymentValidated}

        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 200, padding: "1em 0em" }}
          vertical
        >
          <Container>
            <Header
              as="h1"
              content="Making a video is easy."
              inverted
              style={{
                fontSize: "4em",
                fontWeight: "normal",
                marginBottom: 0,
                marginTop: "1.5em",
              }}
            />
          </Container>
        </Segment>

        <Form size="big">
          <Container style={{ marginTop: "4em" }}>
            <Form.Group>
              <Form.Field>
                <label>Enter your Twitch username</label>
                <Input
                  placeholder="Twitch Username"
                  defaultValue={this.state.twitchName}
                  onChange={this.handleTwitchName}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Enter your contact email</label>
                <Input
                  name="email"
                  placeholder={"email"}
                  defaultValue={this.state.contactEmail}
                  onChange={(e) =>
                    this.setState({ contactEmail: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Field>
                <Form.Checkbox
                  label="Include an intro and outro video"
                  checked={this.state.useIntroOutro}
                  onClick={(e) =>
                    this.setState({
                      useIntroOutro: !this.state.useIntroOutro,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            {optionalButtonGroup}
          </Container>
        </Form>
        <Container style={{ marginTop: "4em" }}>
          <Header>Select the video type you want</Header>
          <Grid columns={3} stackable>
            <Grid.Column>
              <PaymentOption
                clicked={productIdClicked}
                product={products[0]}
                handleClick={this.setProductIdClicked}
              />
            </Grid.Column>
            <Grid.Column>
              <PaymentOption
                clicked={productIdClicked}
                product={products[1]}
                handleClick={this.setProductIdClicked}
              />
            </Grid.Column>
            <Grid.Column>
              <PaymentOption
                clicked={productIdClicked}
                product={products[2]}
                handleClick={this.setProductIdClicked}
              />
            </Grid.Column>
          </Grid>
        </Container>

        <Container>
          <Header>
            You can add, remove, or reorder the clips (if you want)
          </Header>
          <AdvancedVideo
            videos={videos}
            selectedVideos={selectedVideos}
            orderedVideos={orderedVideos}
            currentSlug={currentSlug}
            handleSelectedVideo={this.handleSelectedVideo}
            onDragEnd={this.onDragEnd}
          />
        </Container>

        <Container style={{ marginTop: "4em" }}>
          <Form size="big">
            <Form.Group>
              <Form.Field>
                <label>Enter a Video Title</label>
                <Input
                  name="youtube-title"
                  defaultValue={"Your clipthat.org video"}
                  onChange={(e) =>
                    this.setState({ youtubeTitle: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Enter a Video Description</label>
                <TextArea
                  name="youtube-description"
                  defaultValue={this.state.youtubeDescription}
                  onChange={(e) =>
                    this.setState({ youtubeDescription: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <Radio
                  label="public"
                  name="radioGroup"
                  value="public"
                  checked={this.state.youtubePrivacy === "public"}
                  onChange={(e, { value }) =>
                    this.setState({ youtubePrivacy: value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="private"
                  name="radioGroup"
                  value="private"
                  checked={this.state.youtubePrivacy === "private"}
                  onChange={(e, { value }) =>
                    this.setState({ youtubePrivacy: value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="unlisted"
                  name="radioGroup"
                  value="unlisted"
                  checked={this.state.youtubePrivacy === "unlisted"}
                  onChange={(e, { value }) =>
                    this.setState({ youtubePrivacy: value })
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Container>

        <Container text style={{ margin: "4em 0em" }}>
          <Header>
            After payment, a private YouTube video will be uploaded to your
            account.
          </Header>
        </Container>
        <Container text>
          <ErrorBoundry>{PaypalButton}</ErrorBoundry>
        </Container>
      </div>
    );
  }
}
