import react, { useState } from "react";
import FileUploader from "./FileUploader";
import { BACKEND } from "../url";
import { Icon, Loader } from "semantic-ui-react";

export const UPLOAD_STATE = {
  FAILED: "FAILED",
  PASSED: "PASSED",
  PRIOR: "PRIOR",
  NO_PRIOR: "NO_PRIOR",
  IN_PROGRESS: "IN_PROGRESS",
};

async function uploadThumbnailQuery(
  youtubeId: string,
  imageType: string,
  imagePosition: number
) {
  const query = new URLSearchParams({
    youtubeId,
    imageType,
    imagePosition: imagePosition.toString(),
  });
  const result = await fetch(`${BACKEND}/v1/uploadThumbnailUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

interface ThumbnailUploaderProps {
  style?: react.CSSProperties;
  imageX: number;
  imageY: number;
  thumbnailType: "bg" | "fg";
  youtubeId: string;
  setLoadState(uploadState: string): void;
  uploadState: string;
}
export default function ThumbnailUploader(props: ThumbnailUploaderProps) {
  const [files, setFiles] = useState<File[]>([]);

  const handleRemove = (e: any, index: number) => {
    const newFiles = files.filter((file, i) => i !== index);

    setFiles(newFiles);
  };

  function handleSelect(e: react.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const f = e.target.files;

    if (f && f.length) {
      const newFiles = Array.from(f);

      for (let nf of newFiles) {
        if (nf.size > 5_000_000) {
          console.warn("filesize greater than 5mb");
          return;
        }
      }
      setFiles((files) => files.concat(Array.from(f)).slice(0, 5));
    }
  }

  async function handleUpload() {
    try {
      props.setLoadState(UPLOAD_STATE.IN_PROGRESS);
      let index = 0;
      for (let file of files) {
        const uploadUrl = await uploadThumbnailQuery(
          props.youtubeId,
          props.thumbnailType,
          index
        ).then((res) => res.uploadUrl);

        const result = await fetch(uploadUrl, {
          method: "PUT",
          body: file,
        });
        index += 1;
      }
      props.setLoadState(UPLOAD_STATE.PASSED);
    } catch (err) {
      console.error(err);
      props.setLoadState(UPLOAD_STATE.FAILED);
    }
  }

  return (
    <>
      <FileUploader
        style={{
          marginTop: "4em",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "flex-start",
        }}
        type={props.thumbnailType}
        files={files}
        imageX={props.imageX}
        imageY={props.imageY}
        uploadState={props.uploadState}
        handleSelect={handleSelect}
        handleUpload={handleUpload}
        handleRemove={handleRemove}
      />
    </>
  );
}
