import React from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Segment,
  Step,
} from "semantic-ui-react";

export default function Intro() {
  return (
    <>
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <Header textAlign="center" as="h1">
            Create YouTube highlight videos using your Twitch clips in three
            easy steps
          </Header>
        </Container>
        <Container style={{ padding: "2em 0em" }}>
          <Grid verticalAlign="middle">
            <Grid.Row centered>
              <Step.Group>
                <Step>
                  <Step.Title>
                    <Icon name="youtube play" size="huge" />
                  </Step.Title>
                  <Step.Description style={{ fontSize: "1.33em" }}>
                    Connect your YouTube
                  </Step.Description>
                </Step>
                <Step>
                  <Step.Title>
                    <Icon name="twitch" size="huge" />
                  </Step.Title>
                  <Step.Description style={{ fontSize: "1.33em" }}>
                    Connect your Twitch
                  </Step.Description>
                </Step>
                <Step>
                  <Step.Title>
                    <Icon name="upload" size="huge" />
                  </Step.Title>
                  <Step.Description style={{ fontSize: "1.33em" }}>
                    One click video creation
                  </Step.Description>
                </Step>
              </Step.Group>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment style={{ padding: "8em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em", fontStyle: "italic" }}>
            "This is a huge timesaver."
          </Header>
          <Header as="h3" style={{ fontSize: "2em", fontStyle: "italic" }}>
            "There's no way I could've managed a YouTube channel before this."
          </Header>
          <Header as="h3" style={{ fontSize: "2em", fontStyle: "italic" }}>
            "Worth every penny."
          </Header>
        </Container>
      </Segment>
    </>
  );
}
