import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 4;

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,

  // height: "3em"
  overflow: "auto",
});

export default class VideoEditor extends React.Component {
  props: any;
  getItemStyle = (isDragging: any, draggableStyle: any, index: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    color: "white",
    padding: "5px",
    display: "flex",
    flexGrow: 1,
    // maxWidth: "4em",
    height: "2em",
    margin: `0 ${1}px 0 0`,

    // change background colour if dragging
    background: isDragging
      ? "rgba(0,0,0,1)"
      : // : this.props.orderedVideos[index].color,
        "rgba(70,70,70,1)",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { selectedVideos } = this.props;

    return (
      <DragDropContext onDragEnd={this.props.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided: any, snapshot: any) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {this.props.orderedVideos.map((item: any, index: any) => {
                // we use selectedVideos index to "tag" reordered elements with a reference
                // the user can use to understand what video he's moving around
                const selected_video_index = selectedVideos.findIndex(
                  (sv: any) => sv.id === item.id
                );

                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          index
                        )}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            textAlign: "center",
                            margin: "0 auto",
                          }}
                        >
                          {selected_video_index + 1}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
// <div key={this.props.key} style={{ backgroundColor: "#ff0000", width: "100px", height: "100px" }}>
