import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";

import { Switch, Route, Link, useLocation } from "react-router-dom";

import Videos from "./Videos";
import CreateVideo from "./CreateVideo";
import Subscribe from "./Subscribe";
import { BACKEND } from "../url";
import Tos from "./Tos/Tos";
import Privacy from "./Privacy/Privacy";
import { Media } from "../Media";
import ThumbnailUploader from "./ThumbnailUploader";

export default function LoggedIn(props: any) {
  const { youtubeId, youtubeName, youtubeAvatar } = props;
  const location = useLocation();

  return (
    <div>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 100, padding: "1em 0em" }}
        vertical
      >
        <Container>
          <Menu inverted pointing secondary size="large">
            <Link to="/">
              <Menu.Item active={location.pathname === "/"}>Home</Menu.Item>
            </Link>
            <Link to="/subscribe">
              <Menu.Item active={location.pathname === "/subscribe"}>
                Subscribe
              </Menu.Item>
            </Link>
            <Link to="/videos">
              <Menu.Item active={location.pathname === "/videos"}>
                Videos
              </Menu.Item>
            </Link>
            <Menu.Item position="right">
              <a
                href={`https://www.youtube.com/channel/${youtubeId}`}
                target="_blank"
                rel="noreferrer"
              >
                {youtubeAvatar ? <Image src={youtubeAvatar} avatar /> : null}
              </a>
              <a
                href={`https://www.youtube.com/channel/${youtubeId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Media greaterThan="sm">{youtubeName}</Media>
              </a>
              <Button
                as="a"
                href={`${BACKEND}/v1/logout`}
                inverted
                style={{ marginLeft: "0.5em" }}
              >
                Log Out
              </Button>
            </Menu.Item>
          </Menu>
        </Container>
      </Segment>
      <Switch>
        <Route exact path="/">
          <CreateVideo {...{ youtubeId, youtubeName, youtubeAvatar }} />
        </Route>
        <Route path="/videos">
          <Videos {...{ youtubeId, youtubeName, youtubeAvatar }} />
        </Route>
        <Route path="/tos">
          <Container style={{ minHeight: 800 }}>
            <Tos />
          </Container>
        </Route>
        <Route exact path="/subscribe">
          <Subscribe {...{ youtubeId, youtubeName, youtubeAvatar }} />
        </Route>
        <Route path="/privacy">
          <Container style={{ minHeight: 800 }}>
            <Privacy />
          </Container>
        </Route>
      </Switch>

      <Segment inverted vertical style={{ padding: "4em 0em" }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Contact" />
                <List link inverted>
                  <List.Item as="a">support@clipthat.org</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link to="/tos">Terms of Service</Link>
              </Grid.Column>
              <Grid.Column width={3}>
                <Link to="/privacy">Privacy Policy</Link>
              </Grid.Column>
              <Grid.Column width={3}>
                <a href={`${BACKEND}/v1/revoke`}>Revoke YouTube Access</a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
}
