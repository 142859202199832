import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Container,
  Header,
  Segment,
  Table,
  Form,
  Input,
  Button,
} from "semantic-ui-react";
import { BACKEND } from "../url";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { SubscriptionBody } from "../../types/request-body";
import ThumbnailUploader from "./ThumbnailUploader";
import IntroOutro from "./IntroOutro";
dayjs.extend(utc);
dayjs.extend(relativeTime);

async function updateSubscription(message: SubscriptionBody) {
  const result = await fetch(`${BACKEND}/v1/subscription`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(message),
  });

  return result;
}
async function activeSubscriptions(youtubeId: string) {
  const query = new URLSearchParams({
    youtubeId,
    active: "true",
  });
  const result = await fetch(`${BACKEND}/v1/subscription?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

async function getVideos() {
  const result = await fetch(`${BACKEND}/v1/videos`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());

  return result;
}

export default function Videos(props: any) {
  const [videos, setVideos] = useState<any[]>([]);
  const [activeSubs, setActiveSubs] = useState<any>([]);
  const [selectedSub, setSelectedSub] = useState<number | null>(null);
  const [subState, setSubState] = useState<Partial<SubscriptionBody>>({});

  const [backgroundState, setBackgroundState] = useState<string>("NO_PRIOR");
  const [foregroundState, setForegroundState] = useState<string>("NO_PRIOR");
  const [introButtonState, setIntroButtonState] = useState<string>("NO_PRIOR");
  const [outroButtonState, setOutroButtonState] = useState<string>("NO_PRIOR");

  useEffect(() => {
    const fetchData = async () => {
      const videoResult = await getVideos();
      setVideos(videoResult);

      const subResult = await activeSubscriptions(props.youtubeId);
      setActiveSubs(subResult);
    };
    fetchData();
  }, []);
  const RenderTable =
    Object.keys(videos).length === 0 ? (
      "No videos yet!"
    ) : (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Payment</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {(videos as any).videos
            .sort((a: any, b: any) => {
              const date1 = new Date(a.queue_time);
              const date2 = new Date(b.queue_time);
              return Number(date1) - Number(date2);
            })
            .map((v: any, i: any) => {
              const trimmedTimeStamp = v.queue_time
                ? //  ? v.queue_time.substring(0, 22)
                  v.queue_time
                : null;
              return (
                <Table.Row key={`${trimmedTimeStamp} ${i}`}>
                  <Table.Cell>
                    {dayjs(trimmedTimeStamp).utc(true).fromNow()}
                  </Table.Cell>
                  <Table.Cell>{v.payment_amount}</Table.Cell>
                  <Table.Cell>{v.status}</Table.Cell>
                  <Table.Cell>
                    <a
                      href={`https://youtube.com/watch?v=${v.youtube_video_id}`}
                    >
                      {v.youtube_video_id}
                    </a>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  const optionalButtonGroup = !subState.useIntroOutro ? null : (
    <>
      <Form.Group>
        <Form.Field>
          <IntroOutro
            buttonState={introButtonState}
            handleButtonState={(state: string) => setIntroButtonState(state)}
            content={"Intro video"}
            videoType={"intro"}
            youtubeId={props.youtubeId}
          />
        </Form.Field>
        <Form.Field>
          <IntroOutro
            buttonState={outroButtonState}
            handleButtonState={(state: string) => setOutroButtonState(state)}
            content={"Outro video"}
            videoType={"outro"}
            youtubeId={props.youtubeId}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Header as="h6">
            720p video with the same framerate as your stream will work best.
          </Header>
        </Form.Field>
      </Form.Group>
    </>
  );

  const optionalThumbnailGroup = !subState.useThumbnail ? null : (
    <>
      <Form.Group>
        <Form.Field>
          <Segment>
            <Header as="h5">Background Images</Header>
            <Header as="h6">
              Background images should be ~1280x720 resolution.
            </Header>
            <ThumbnailUploader
              thumbnailType="bg"
              imageX={160}
              imageY={90}
              uploadState={backgroundState}
              setLoadState={(state: string) => setBackgroundState(state)}
              youtubeId={props.youtubeId}
            />
          </Segment>
        </Form.Field>
        <Form.Field></Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Segment>
            <Header as="h5">Foreground Images</Header>
            <Header as="h6">
              Foreground images should be ~426x720 resolution.
            </Header>
            <ThumbnailUploader
              thumbnailType="fg"
              imageX={53}
              imageY={90}
              uploadState={foregroundState}
              setLoadState={(state: string) => setForegroundState(state)}
              youtubeId={props.youtubeId}
            />
          </Segment>
        </Form.Field>
      </Form.Group>
    </>
  );

  const subscriptionSettings = (
    <Container style={{ marginTop: "4em" }}>
      <Form size="big" style={{ marginTop: "4em" }}>
        <Container style={{ marginTop: "4em" }}>
          <Header as="h3">Subscription Settings</Header>
          <Form.Group>
            <Form.Field>
              <label>Twitch username</label>
              <Input
                placeholder="Twitch Username"
                value={subState?.twitchName}
                onChange={(e, data) =>
                  setSubState({ ...subState, twitchName: data.value })
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Email</label>
              <Input
                name="email"
                placeholder={"email"}
                defaultValue={subState?.email}
                onChange={(e, data) =>
                  setSubState({ ...subState, email: data.value })
                }
              />
            </Form.Field>
          </Form.Group>
        </Container>

        <Container>
          <Form.Group>
            <Form.Field>
              <Form.Checkbox
                label="Include an intro and outro video"
                checked={subState.useIntroOutro}
                onClick={(e, data) =>
                  setSubState({
                    ...subState,
                    useIntroOutro: !subState.useIntroOutro,
                  })
                }
              />
            </Form.Field>
          </Form.Group>
          {optionalButtonGroup}
        </Container>

        <Container>
          <Form.Group>
            <Form.Field>
              <Form.Checkbox
                label="include thumbnails"
                checked={subState.useThumbnail}
                onClick={(e, data) =>
                  setSubState({
                    ...subState,
                    useThumbnail: !subState.useThumbnail,
                  })
                }
              />
            </Form.Field>
          </Form.Group>
          {optionalThumbnailGroup}
        </Container>
        <Container>
          <Form.Group>
            <Form.Field>
              <Form.Checkbox
                label="Try to censor inappropriate content"
                checked={subState.censor}
                onClick={(e) =>
                  setSubState({
                    ...subState,
                    censor: !subState.censor,
                  })
                }
              />
            </Form.Field>
          </Form.Group>
        </Container>

        <Container style={{ margin: "4em 0em" }}>
          <Form.Group>
            <Form.Field>
              <label>Number of clips per video</label>
              <Input
                placeholder="number of clips"
                value={subState.numClips}
                onChange={(e, data) =>
                  setSubState({ ...subState, numClips: Number(data.value) })
                }
              />
            </Form.Field>
          </Form.Group>
          <Button
            onClick={async (e: any) => {
              const res = await updateSubscription(
                subState as SubscriptionBody
              );
              if (res.ok) {
                const newSub = activeSubs;
                newSub[selectedSub as number].message = subState;
                setActiveSubs(newSub);
                setSelectedSub(null);
                setSubState({});
              }
            }}
          >
            Update Subscription
          </Button>
        </Container>
      </Form>
    </Container>
  );
  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 200, padding: "1em 0em" }}
        vertical
      >
        <Container>
          <Header
            as="h1"
            content="Your Videos"
            inverted
            style={{
              fontSize: "4em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "1.5em",
            }}
          />
        </Container>
      </Segment>
      <Container style={{ marginTop: "4em" }}>
        <Header>Active Subscriptions</Header>
        <Dropdown
          onChange={(e, data) => {
            setSelectedSub(data.value as number);
            setSubState(activeSubs[data.value as number]?.message);
          }}
          placeholder="Select subscription"
          fluid
          selection
          options={activeSubs.map((s: any, index: number) => {
            return { key: index, text: s.type, value: index };
          })}
        />
      </Container>
      {Object.keys(subState).length > 0 ? subscriptionSettings : null}

      <Container style={{ marginTop: "4em" }}>
        <Header>Videos Created</Header>
      </Container>
      <Container style={{ minHeight: 700, marginTop: "4em" }}>
        {RenderTable}
        <Header as="h5">
          A private video will be visible in YouTube Studio when the status in
          the table says "finished".
        </Header>
      </Container>
    </>
  );
}
