import React, { Component, ReactNode } from "react";
//todo rename
import LoggedIn from "./components/LoggedIn";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router } from "react-router-dom";

import { BACKEND } from "./url";
import LoggedOut from "./components/LoggedOut/LoggedOut";
import { MediaContextProvider } from "./Media";

type State = any;
class App extends React.Component<{}, State> {
  state = {
    id: null,
    username: "",
    thumbnail: "",
    session: null,
  };

  componentDidMount = async (): Promise<void> => {
    const data = await fetch(`${BACKEND}/v1/session`, {
      credentials: "include",
    }).then((resp) => {
      return resp.json();
    });
    console.log(data);
    this.setState({ ...data });
  };

  render(): ReactNode {
    const { id, username, thumbnail, session } = this.state;
    const component =
      session === null ? null : session === false ? (
        <Router>
          <LoggedOut />
        </Router>
      ) : (
        <Router>
          <LoggedIn
            youtubeId={id}
            youtubeName={username}
            youtubeAvatar={thumbnail}
          />
        </Router>
      );

    return <MediaContextProvider>{component}</MediaContextProvider>;
  }
}

export default App;
