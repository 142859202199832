import React from "react";
import { Popup, Loader, Button, Icon } from "semantic-ui-react";
import "url-search-params-polyfill";

export const UPLOAD_STATE = {
  FAILED: "FAILED",
  PASSED: "PASSED",
  PRIOR: "PRIOR",
  NO_PRIOR: "NO_PRIOR",
  IN_PROGRESS: "IN_PROGRESS",
};

export function UploadButton(props: any) {
  let indicator = null;
  if (props.buttonState === UPLOAD_STATE.NO_PRIOR) {
    indicator = null;
  } else if (props.buttonState === UPLOAD_STATE.IN_PROGRESS) {
    indicator = <Loader size="mini" inline active />;
  } else if (props.buttonState === UPLOAD_STATE.FAILED) {
    indicator = <Icon color="red" name="warning circle" />;
  } else if (props.buttonState === UPLOAD_STATE.PASSED) {
    indicator = (
      <Popup
        inverted
        content="Video successfully uploaded"
        trigger={<Icon color="green" name="check" />}
      />
    );
  } else if (props.buttonState === UPLOAD_STATE.PRIOR) {
    indicator = (
      <Popup
        inverted
        content="We remember an old video you uploaded"
        trigger={<Icon name="check" />}
      />
    );
  }

  return (
    <div>
      <Button
        as="label"
        htmlFor={props.content}
        icon="file"
        type="button"
        content={props.content}
      />
      {indicator}
      <input
        type="file"
        id={props.content}
        hidden
        onChange={props.handleUpload}
      />
    </div>
  );
}
