import { trimURL, FRONTEND } from "../url";
import React from "react";

// append autoplay=false as querystring if needed not as iframe var
const VideoPlayer = ({
  slug
}: any) => {
  const source_url = `https://clips.twitch.tv/embed?clip=${slug}&parent=${trimURL(
    FRONTEND
  )}`;
  // if (!slug) {
  //   return null;
  // }
  return (
    <iframe
      title="Video Player"
      style={{
        flexGrow: 1,
      }}
      height="400"
      width="100%"
      src={source_url}
      frameBorder="0"
      scrolling="no"
      allowFullScreen={true}
    />
  );
};

export default VideoPlayer;
