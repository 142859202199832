import react, { useEffect } from "react";
import { BACKEND } from "../url";
import { UPLOAD_STATE, UploadButton } from "./UploadButton";

async function uploadQuery(youtubeId: any, videoType: "intro" | "outro") {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/uploadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

async function downloadQuery(youtubeId: any, videoType: any) {
  const query = new URLSearchParams({
    youtubeId,
    videoType,
  });
  const result = await fetch(`${BACKEND}/v1/downloadUrl?${query}`, {
    method: "GET",
    credentials: "include",
  }).then((res) => res.json());
  return result;
}

interface IntroOutroProps {
  buttonState: string;
  handleButtonState(buttonState: string): void;
  content: string;
  youtubeId: string;
  videoType: "intro" | "outro";
}

export default function IntroOutro(props: IntroOutroProps) {
  useEffect(() => {
    const getVids = async () => {
      const downloadResult = await downloadQuery(
        props.youtubeId,
        props.videoType
      );
      downloadResult.downloadUrl === ""
        ? props.handleButtonState(UPLOAD_STATE.NO_PRIOR)
        : props.handleButtonState(UPLOAD_STATE.PRIOR);
    };

    getVids();
  }, []);

  const handleUpload = async (event: any) => {
    try {
      event.preventDefault();
      console.log(event.target);
      const file = event.target.files[0];
      if (!file.type.includes("video")) {
        console.warn("trying to upload non video file");
        return;
      }
      if (file.size > 50_000_000) {
        console.warn("trying to upload big boi file");
        return;
      }
      props.handleButtonState(UPLOAD_STATE.IN_PROGRESS);

      const urlResult = await uploadQuery(props.youtubeId, props.videoType);
      const targetUrl = urlResult.uploadUrl;

      const result = await fetch(targetUrl, {
        method: "PUT",
        body: file,
      });
      if (result.ok) {
        props.handleButtonState(UPLOAD_STATE.PASSED);
        console.log("uploaded");
      } else {
        console.error("not ok ");
        props.handleButtonState(UPLOAD_STATE.FAILED);
      }
    } catch (err) {
      console.error(err);
      props.handleButtonState(UPLOAD_STATE.FAILED);
    }
  };

  return (
    <UploadButton
      buttonState={props.buttonState}
      handleUpload={handleUpload}
      content={props.content}
    />
  );
}
