import React from "react";
import {
  FUNDING,
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";

import { PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";

/**
 * paypal related configuration
 */
const clientId =
  process.env.REACT_APP_ENV === "production"
    ? //production
      "AXpdbM52bI7wDJhYl16floAeqff_iXjxNAD91U6mAJ4TikHBNa6ZUdTyjvDItcCHI50fFhUs7Ca94nOP"
    : //sandbox
      "AR_6onSRXSk2Q7ZgMrngNgJwENjYRXqm9TpfF877g-N9hgkKJwgalkQ06uVqb_aZDqFOQmkrSKUCVZcu";

const initialOptions = {
  "client-id": clientId,
  currency: "USD",
};

export default class Paypal extends React.Component {
  props: any;
  createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: { value: this.props.amount },
        },
      ],
    });
  };

  onApprove = (data: any, actions: any) => {
    const { handlePayment } = this.props;
    console.log(data);
    return actions.order.capture().then(function (paymentData: any) {
      // Show a success page to the buyer
      console.log(paymentData);
      handlePayment(paymentData);
    });
  };

  onError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={this.createOrder}
          onApprove={this.onApprove}
          fundingSource={FUNDING.PAYPAL}
          style={{
            color: "black",
            shape: "rect",
            label: "checkout",
          }}
        />
      </PayPalScriptProvider>
    );
  }
}
